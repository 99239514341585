/* root */
.pointer {
  cursor: pointer;
}

.text-white {
  color: #ffffff;
}
.text-black {
  color: #000 !important;
}
/* .text1{
} */
/* .text1-125 {
} */
.text0-875os {
  font-size: 0.875rem;
  font-family: "Open Sans", sans-serif;
  color: #000;
}

.weight-500 {
  font-weight: 500;
}

.weight-600 {
  font-weight: 600;
}

.btn {
  all: unset;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
}
/*root end*/

/* Navbar start*/
.navbarTransparent {
  color: #fff;
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  padding-top: 0.375rem;
  padding-right: 2.5rem;
  padding-bottom: 0.25rem;
  padding-left: 1.875rem;
  z-index: 10;
  position: absolute;
  width: 100%;
}

.navbarWhite {
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  padding-top: 0.375rem;
  padding-right: 2.5rem;
  padding-bottom: 0.25rem;
  padding-left: 1.875rem;
  background: #fafafa;
}

.nav-link {
  margin-right: 2.5rem;
}

.nav-link:last-child {
  margin-right: 6.25rem;
}

.dropdown-toggle::after {
  display: none;
}

.dropdownBoxTranslucent {
  background: #fff9;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  font-weight: 400;
}

.dropdownBoxWhite {
  background: #fff9;
  box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.17);
  border-radius: 0.25rem;
  font-size: 0.875rem;
  font-weight: 400;
}

.dropdown-divider {
  margin: 0.2rem;
}

/* Navbar end */

/* HOME */
.titles {
  font-family: "Lato", sans-serif;
  color: #000;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
}

.welcomeText {
  color: #000;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: "Lato", sans-serif;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: var(--typography-size-line-height-lg-3, 2.125rem);
}

.overlay {
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 22.6875rem;
  height: auto;
  padding: 1rem;
}

#availabilityBtn {
  width: 100%;
  box-shadow: none;
}
#availabilityBtn {
  width: 100%;
}

@media (max-width: 768px) {
  .overlay {
    width: 95%;
    padding: 0.5rem;
    left: 50%;
  }

  #availabilityBtn {
    width: 75%;
  }
}

.line {
  width: 4.375rem;
  height: 0.0625rem;
  background-color: #000000;
}

.placeInterestContainer {
  background-color: #fafafa;
  border-radius: 1.25rem;
  font-family: "Open Sans", sans-serif;
  width: 26.5625rem;
  padding-left: 1.12rem;
  padding-right: 0.75rem;
  padding-bottom: 0.62rem;
  padding-top: 0.62rem;
}

.placeInterestTitle {
  font-size: 1.125rem;
  margin-bottom: 0.9375rem;
  font-weight: 400;
  color: #000000;
}
.placeInterestDescription {
  margin-right: 0.25rem;
  font-size: 1rem;
  font-weight: 400;
  color: #383838;
}

/* Carousel */
.image-carousel-container {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
}

.image-container-wrapper {
  overflow: hidden;
  flex-grow: 1;
  position: relative;
}

.image-container {
  display: flex;
  transition: transform 0.4s ease-in-out;
  will-change: transform;
  gap: 0.25rem;
}

.carousel-image {
  flex: 0 0 auto;
  transition: transform 0.5s ease-in-out;
  object-fit: cover;
  aspect-ratio: 16/9;
}

.carousel-button {
  /* this is also used in guest book */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.prev-button {
  /* this is also used in guest book */
  position: absolute;
  left: 10px;
}

.next-button {
  /* this is also used in guest book */
  position: absolute;
  right: 10px;
}

/* Guest book */
.guestContainer {
  background-color: rgb(104, 166, 145, 0.1);
  padding-top: 3.125rem;
  padding-bottom: 5.625rem;
  padding-left: 3.8125rem;
  margin-top: 6.25rem;
}

.review {
  width: 17.5rem;
  height: 17.5rem;
  padding: var(--typography-size-line-height-sm-3, 1rem) 1.25rem 1.25rem 1.25rem;
  margin-top: 2rem;
  margin-right: 0.3125rem;
  border-radius: 0.375rem;
  border: 0.5px solid rgba(104, 166, 145, 0.2);
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.review:last-child {
  margin-right: 0;
}

.reviewComment {
  margin-top: var(--typography-size-line-height-sm-3, 1rem);
  margin-bottom: 0.375rem;
  color: #000;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1.25rem;
}

.reviewAuthor {
  color: #383838;
  font-family: "Open Sans", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  margin-bottom: 0;
}

.reviewDate {
  color: rgba(175, 175, 175, 0.98);
  font-family: "Open Sans", sans-serif;
  font-size: 0.625rem;
  font-style: italic;
  font-weight: 400;
  line-height: 110%;
  margin-bottom: 0;
}

.stars {
  display: flex;
}

.full-star,
.empty-star {
  font-size: 0.9375rem;
  color: #fdb609;
}

.empty-star {
  color: #e0e0e0;
}
.half-star {
  position: relative;
  display: inline-block;
  font-size: 0.9375rem;
  color: #e0e0e0;
}

.half-star::before {
  content: "★";
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  overflow: hidden;
  color: #fdb609;
}

.carousel {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
}

.carousel-container {
  overflow: hidden;
  width: 100%;
  display: flex;
  transition: transform 0.5s ease-in-out;
}

/* contact us */
.contactContainer {
  border-radius: 2rem;
  background: #fcfcfc;
  padding-left: 3.125rem;
  padding-top: 4.375rem;
  padding-right: 3.125rem;
  padding-bottom: 5rem;
  margin-top: 6.25rem;
  margin-bottom: 14.6875rem;
}

.contactTitle {
  color: #000;
  font-family: "Lato", sans-serif;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.875rem;
}

.contactDescription {
  color: #000;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.625rem;
}

.contactInfo {
  color: #383838;
  font-family: "Open Sans", sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.625rem;
}

.contactContainer input,
.contactContainer textarea {
  border-radius: 0.625rem;
  border: 1px solid #c5c5c5;
  background-color: #f9f9f9;
  color: #c5c5c5;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.625rem;
}

.contactContainer input:focus,
.contactContainer textarea:focus {
  border: 1px solid #68a691;
  background: #fff;
  box-shadow: none;
}

.contactButton {
  border-radius: var(--typography-size-line-height-md-3, 1.5rem);
  background-color: #68a691;
  height: 2.8125rem;
}
.contactButton:disabled {
  background-color: #6ca18f94;
}

/* Calendar modal */
.modal-95w {
  max-width: 95%;
}
.modal-content {
  border-radius: 1.125rem;
}

.requestButton {
  font-family: "Lato", sans-serif;
  border-radius: 0.5rem;
  background-color: #68a691;
  color: #fff;
  border: none;
  font-size: 1.125rem;
}
.requestButton:disabled {
  background-color: #68a691a3;
}

.checkinoutbox {
  background-color: #e0e0e0;
  border-radius: 2.0625rem;
  height: 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  font-size: 0.75rem;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);
}

.checkin-col {
  padding-left: 5%;
  margin-right: 5%;
  flex: 1;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.checkout-col {
  flex: 1;
  cursor: pointer;
  margin-left: 5%;
  transition: background-color 0.3s ease;
}

.divider {
  width: 2px;
  height: 70%;
  background-color: #ccc;
  align-self: center;
}

.checkin-col.active,
.checkout-col.active {
  background-color: #ffffff;
  border-radius: 2.0625rem;
  height: inherit;
  display: flex;

  align-items: center;
}
/* Calendar */
.calendarTitle {
  font-size: 1.125rem;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: #000;
}

.calendarLeft {
  border: none;
  border-radius: 1.875rem 0 0 1.875rem;
  box-shadow: 0px 10px 20px 20px rgba(0, 0, 0, 0.05);
  padding: 1rem;
  max-width: 312px;
}

.calendarRight {
  border: none;
  border-radius: 0 1.875rem 1.875rem 0;
  box-shadow: 20px 10px 20px 0px rgba(0, 0, 0, 0.05);
  padding: 1rem;
  max-width: 312px;
}

.calendarOnlyOne {
  border: none;
  border-radius: 1.875rem;
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.05);
  padding: 1rem;
  max-width: 312px;
}

.calendar .react-calendar__month-view__days__day--weekend,
.calendar .react-calendar__month-view__days__day--neighbor,
.calendar .react-calendar__tile {
  border-radius: inherit;
}
/* Días deshabilitados */
.unavailable-day {
  color: gray;
  background-color: white;
  pointer-events: none;
}

/* Fecha de entrada y salida */
.selected-checkin,
.selected-checkout {
  background-color: black;
  color: white !important;
  border-radius: 50%;
  z-index: 2;
}

/* Días seleccionados: Entrada, Salida, y Rango */
.selected-checkin:hover,
.selected-checkin:focus,
.selected-checkout:focus,
.selected-checkout:hover {
  background-color: #333 !important;
}

/* Días entre entrada y salida */
.selected-range {
  background-color: #e0e0e0 !important;
  color: black;
  /* box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);  hacer algo parecido al primer y al ultimo*/
}
.selected-range:hover {
  background-color: #d6d6d6;
  color: black;
}
/* Fines de semana */
.react-calendar__month-view__days__day--weekend {
  color: black;
}

/* Flechas y dias deshabilitadas */
.react-calendar__navigation__label__labelText--from:disabled,
.react-calendar__month-view__days__day:disabled,
.react-calendar__navigation__arrow:disabled,
.react-calendar__navigation__label:disabled {
  background-color: white !important;
  color: rgba(128, 128, 128, 0.566);
}

/* Hover en las flechas y fecha cuando están deshabilitadas */
.react-calendar__navigation__arrow:disabled:hover,
.react-calendar__navigation__label:disabled:hover {
  background-color: white !important;
  color: rgba(128, 128, 128, 0.566);
}

/* Flechas y fecha habilitadas (estilo hover) */
.react-calendar__navigation__arrow:hover,
.react-calendar__navigation__arrow:focus,
.react-calendar__navigation__label:focus,
.react-calendar__navigation__label:hover {
  color: black;
  background-color: white !important;
}
/* Quitar el cursor de ayuda en los días de la semana */
.react-calendar__month-view__weekdays__weekday > * {
  cursor: default !important;
}

/* Summary Modal */

/*  */

/* footer */
.footer {
  background-color: #f8f8f8;
  padding-top: 2rem;
}

.followUs {
  color: #383838;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.mainTitle {
  color: #000;
  font-family: "Open Sans", sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  /* line-height: 250%; */
  margin: 0;
}

.footerLink {
  color: #383838;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 250%;
  margin: 0;
}

.footerDivider {
  border-top: 0.5px solid #c5c5c5;
  margin-top: 1.5rem;
}

.allrights {
  color: #c5c5c5;
  font-family: "Lato", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  margin-bottom: 0;
}
/*  */
/* Gallery */
.galleryNav {
  background-color: #68a691b2;
  font-family: "Lato", sans-serif;
  font-size: 1.125rem;
  border-radius: var(--typography-size-line-height-lg, 1.875rem) var(--typography-size-line-height-lg, 1.875rem) 0rem 0rem;
  padding-left: 1.88rem;
  padding-right: 1.88rem;
  padding-top: 1rem;
}
.galleryPicsContainer {
  border-right: 1px solid #f8f8f8;
  border-left: 1px solid #f8f8f8;
  background: #fafafa;
}

.galleryPic {
  border-radius: 0.5rem;
}

.nav-link {
  color: #000;
}
.nav-link.active {
  border-radius: var(--typography-size-line-height-lg, 1.875rem) var(--typography-size-line-height-lg, 1.875rem) 0rem 0rem;
}
/*  */
/* Accommodation */
.accommodationHr {
  border-top: 0.5px solid #a63d6f;
}
/*  */
